import queryString from "query-string";

export const getQueryString = (search: any, options?: any) => {
    return queryString.stringify(search, options);
};

export const getKeyByValue = (object: any, value: any) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const prettifyJSON = (jsonString:any) =>{
    // Clean the JSON string by removing escape characters
    const cleanedJSONString = jsonString
    .replace(/\\"/g, '"')  // Replace \" with "
    .replace(/\\\\"/g, '"')  // Replace \" with "
    .replace(/\\n/g, '')   // Replace \n with empty space
    .replace(/^"|"$/g, ''); // Remove surrounding quotes

  // Parse the cleaned JSON string into a JavaScript object
  let jsonObject;
  let prettyJSON = '';
  try {
    jsonObject = JSON.parse(cleanedJSONString);
    prettyJSON = JSON.stringify(jsonObject, null, 2); // Pretty-print with indentation
  } catch (error) {
    console.error("Failed to parse JSON:", error);
  }
  return prettyJSON
};

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */    
    return color;
  }
    

export const stringAvatar= (name: string) => {

    const getIntials = (str:string)=>{
      return str?.split('_')            // Split by underscore
            .map(word => word[0])   // Take the first letter of each word
            .join('')               // Join the initials
            .toUpperCase();
    }
    const intials = name?.length >3 ? getIntials(name): name?.toUpperCase()
          return {
      
      sx: {
        // background: `${stringToColor(name)}`,
        // backgroundColor:`${stringToColor(name)}`,
        fontSize: 12,
        width: 30, height: 30
      },
      children: intials,
    };
};

export const isJSON = (str:any) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
};

export const formatXML = (xml:any) => {
    try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'text/xml');
        const serializer = new XMLSerializer();
        let formatted = serializer.serializeToString(xmlDoc);
  
        // Add indentation for readability
        formatted = formatted.replace(/(>)(<)(\/*)/g, '$1\n$2$3');
        formatted = formatted.replace(/(<([a-zA-Z]+)[^>]*>)/g, '  $1');
  
        return formatted;
      } catch (error) {
        console.error('Failed to parse XML:', error);
        return 'Invalid XML data';
      }
};

export const JSONParse =(data:any) => {
    // Check if data is already an object or array
    if (typeof data === 'object' && data !== null) {
      return data; // No parsing needed
    }
  
    // Check if data is a string and might be JSON
    if (typeof data === 'string') {
      try {
        // Attempt to parse the string
        return JSON.parse(data);
      } catch (error) {
        // Parsing failed, return the original data
        console.warn('Data is not JSON format:', error);
        return data;
      }
    }
  
    // Return the original data if it’s neither a JSON string nor an object/array
    return data;
  }

  export const isXML = (data:any) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, "application/xml");
      return xmlDoc.getElementsByTagName("parsererror").length === 0;
    } catch (e) {
      return false;
    }
  }

  export const formatString = (input: string) => {
    return input.toLowerCase().replace(/\s+/g, '');
} 

