import { MyThunkAction } from "../types";
import { APP_ROUTES } from "../utils/constants";
import { get, patch, patchWithHeader, postWithHeader } from "./common";
import { getIntegratedData } from "./IntegrationgAction";
import { sendAlert } from "./UserAction";
import { History } from "history";

export const CLEAN_MARKET_CONFIG = "CLEAN_MARKET_CONFIG";
export const MARKET_CONFIG_LOADING = "MARKET_CONFIG_LOADING";
export const MARKET_CONFIG_SUCCESS = "MARKET_CONFIG_SUCCESS";
export const MARKET_CONFIG_ALERT = "MARKET_CONFIG_ALERT";

export const NEW_MARKET_CONFIG_LOADING = "NEW_MARKET_CONFIG_LOADING";
export const NEW_MARKET_CONFIG_SUCESS = "NEW_MARKET_CONFIG_SUCESS";


export const getMarketConfig = (integrationId:string,history:History): MyThunkAction => async (dispatch: any) => {
    dispatch({ type: MARKET_CONFIG_LOADING });
    try {
      const response = await get(`/integrations/market/config/${integrationId}`);
      if(response?.error){
        dispatch(
          sendAlert({
            text: "Network error, please try again!",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          }),
        );
       history.push(APP_ROUTES.integrations)
        return {status:400}
       }
       dispatch({ type: MARKET_CONFIG_SUCCESS, payload: response });
    } catch (error: any) {
      dispatch({ type: MARKET_CONFIG_ALERT, payload: error.message || "Failed to fetch market config" });
    }
  };

export const CreateNewMarketConfig = (data:any,headers:any,history:History,params:any,isProviderAdmin:boolean,handleOpen:any): MyThunkAction => async (dispatch: any) => {
  
  dispatch({ type: NEW_MARKET_CONFIG_LOADING });
    const response = await postWithHeader(
      `/integrations/market/config`,
      data,
      {
        headers // Timeout set to 5 minutes (300,000 ms)
      },
    );
   if(response?.error){
    dispatch(
      sendAlert({
        text: "Duplicate Market Name",
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      }),
    );
   
   }else{
    dispatch(
      sendAlert({
        text: "Market Created successfully.",
        snack: true,
        snackTimeout: 4000,
        snackType: "success",
      }),
    );
    dispatch({ type: NEW_MARKET_CONFIG_SUCESS, payload: response });
   
    if(!isProviderAdmin){
      history.push(APP_ROUTES.manageStore,params)
    }
    else{
      handleOpen()
    }
    return { status: 200 };
   }
  }


export const UpdateMarketConfig = (data:any,headers:any,history:History,params:any,isProviderAdmin:boolean,handleOpen:any): MyThunkAction => async (dispatch: any) => {
    
  const response= await patchWithHeader(`/integrations/market/config`,data,{headers})

      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Facing Issue",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          }),
        );
        return { status: 400,};
      }
   else{
  // dispatch(getIntegratedData())
   
  dispatch(
        sendAlert({
          text: "Market Updated successfully.",
          snack: true,
          snackTimeout: 4000,
          snackType: "success",
        }),
      );
      dispatch({ type: NEW_MARKET_CONFIG_SUCESS, payload: response });
  if(!isProviderAdmin){
    
   history.push(APP_ROUTES.manageStore,params)
  }
  else{
   handleOpen()
 }
return { status: 200 };
  }
   
     
};

 export const CleanMarketConfig = (): MyThunkAction => async (dispatch: any) => {
     return dispatch({ type: CLEAN_MARKET_CONFIG });
    };
  
    