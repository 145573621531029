delete window.BACKEND_BASE_URL;
delete window.CORS_URL;

const config = {
  BASE_URL:process.env.REACT_APP_BASE_URL,
  ACTUAL_URL:process.env.REACT_APP_BASE_URL,
  STATUS_URL:process.env.REACT_APP_STATUS_URL,
  API_KEY:process.env.REACT_APP_API_KEY,
  region:process.env.REACT_APP_COGNITO_REGION,
  userPoolId:process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId:process.env.REACT_APP_COGNITO_CLIENT_ID
};

export default config;
