import { cloneDeep } from "lodash";
import {
  GET_PROVIDER_CONFIG,
  GET_PROVIDER_CONFIG_LOADING,
  GET_PROVIDER_CONFIG_SUCCESS,
  GET_PROVIDER_CONFIG_ERROR,
  GET_PROVIDERS_LOADING,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,
  PROVIDER_ALERT,
  GET_PROVIDER_CONFIG_MEATA_DATA_LOADING,
  GET_PROVIDER_CONFIG_MATA_DATA_SUCCESS,
  GET_PROVIDER_CONFIG_MEATA_DATA_ERROR,
  CLEAN_PROVIDER_METADATA
} from "../actions";
import { ProviderState } from "../types/providers";
import { initialAlert } from "../utils/constants";


const initialState: ProviderState = {
  providers: [],
  isLoading: true,
  error: null,
  providerConfig: null,
  alert: initialAlert,
  providerConfigMetaData:{}
};

export const providerReducer = (state = initialState, action: any): ProviderState => {
  switch (action.type) {

       // Handle the new actions for metadata
       case GET_PROVIDER_CONFIG_MEATA_DATA_LOADING:
        return { ...state, isLoading: true, error: null };
  
      case GET_PROVIDER_CONFIG_MATA_DATA_SUCCESS:
        return { 
          ...state, 
          providerConfigMetaData: action.payload, 
          
          isLoading: false, 
          error: null 
          };
  
      case GET_PROVIDER_CONFIG_MEATA_DATA_ERROR:
        return { ...state, isLoading: false, error: action.payload };

    case GET_PROVIDER_CONFIG_LOADING:
      return { ...state, isLoading: true, error: null };

    case GET_PROVIDER_CONFIG_SUCCESS:
      return { ...state, providerConfig: action.payload, isLoading: false, error: null };

    case GET_PROVIDER_CONFIG_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case GET_PROVIDER_CONFIG: 
      // Action to reset or handle specific provider config
      return { ...state };

    case GET_PROVIDERS_LOADING:
      return { ...state, isLoading: true, error: null };

    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload.providerIntegrationData, // Use the providerIntegrationData from the response
        isLoading: false,
        error: null
      };

    case GET_PROVIDERS_ERROR:
      return { ...state, isLoading: false, error: action.payload };

       case PROVIDER_ALERT: {
              return {
                  ...state,
                  alert: action.payload,
              };
          }

          case CLEAN_PROVIDER_METADATA:
                return {
                  ...state,
                  providerConfig: null, 
                
                };

    default:
      return state;
  }
};
