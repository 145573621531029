import { ActionType, AlertType } from "./types";
import * as actionTypes from "../actions";
import { initialAlert } from "../utils/constants";
import NewTicket from "../views/Support/NewTicket";
import Tickects from "../views/Support/Tickects";

type UserTypes = {
  tickets: any,
  isLoading: boolean,
  newTicket: any,
  ticketById:any,
  isLoadingTickets: boolean,
  comment:any,
}

const initialState: UserTypes = {
  tickets: [],
  isLoading: true,
  isLoadingTickets:true,
  newTicket: {},
  ticketById: {},
  comment: {},
};

const support = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_TICKETS_LOADING_START:{
      return{
        ...state,
        isLoadingTickets: action.payload
      }
    }
    case actionTypes.ON_TICKETS_LOADING_FINISHED:{
      return{
        ...state,
        isLoadingTickets: action.payload
      }
    }
    case actionTypes.ON_TICKET_LOADING_START:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.ON_TICKET_LOADING_FINISHED:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.ON_CREATE_TICKET: {
      return {
        ...state,
        NewTicket: action.payload,
      };
    }
    case actionTypes.ON_TICKETS_LOAD: {
      return {
        ...state,
        tickets: action.payload,
      };
    }
    case actionTypes.GET_TICKET_BY_ID: {
      return {
          ...state,
          ticketById: action.payload,
      };
    }
    case actionTypes.UPDATE_TICKET_BY_ID:{
      const updatedTicket = state.tickets?.map((t:any)=>{
        if(t.id === action.payload.id){
          return action.payload.res
        }
        return t;
      }) 
      const res =  action.payload.res                     
      return {
        ...state,
        tickets: updatedTicket,
        ticketById:{...res, comments:state.ticketById.comments}
      };
    }
    case actionTypes.ON_ADD_COMMENT:{
      return {
        ...state,
        comment:action.payload.res,
        ticketById:{...state.ticketById, comments:[action.payload.res, ...state.ticketById.comments, ] }
      };
    }
    case actionTypes.ON_UPDATE_COMMENT:{
      const updatedComment = state.ticketById?.comments?.map((c:any)=>{
        if(c.id === action.payload.commentId){
          return action.payload.res
        }
        return c
      })
      return {
        ...state,
        comment: updatedComment,
        ticketById:{...state.ticketById, comments:updatedComment}
      };
    }
    case actionTypes.ON_DELETE_COMMENT:{
      const deleteComment = state.ticketById.comments.filter((c:any)=>c.id !== action.payload.commentId)
      return {
        ...state,
        comment: deleteComment,
        ticketById:{...state.ticketById, comments:deleteComment}
      };
    }
    default: {
      return state;
    }
  }
};

export default support;
