// import { Snackbar, makeStyles, createStyles } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React from "react";
import { useDispatch } from "react-redux";
import { resetAlert } from "../../actions";
import {
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

// function SlideTransition(props: SlideProps) {
//   return <Slide {...props} direction="up" ref={(node) => {
//     if (node) {
//       // Only act if node is valid
//     }
//   }} />;
// }

// function Alert(props: AlertProps) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const Snack: React.FC<any> = ({ open, alert }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => handleClose}
      ContentProps={{
        classes: {
          root: "paper", // <-- apply your custom class here
        },
      }}
      sx={{
        maxWidth: 364,
        zIndex: 9999,
        "& .paper": {
          background:
            alert.snackType === "error"
              ? "#fdeded"
              : alert.snackType === "success"
              ? "#edf7ed"
              : alert.snackType === "warning"
              ? "#fff4e5"
              : "#e5f6fd",
          color:
            alert.snackType === "error"
              ? "#B72136"
              : alert.snackType === "success"
              ? "#229A16"
              : alert.snackType === "warning"
              ? "#F8A647"
              : "#0C53B7",
        },
      }}
      message={alert.text}
      action={<IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
    />
    //   <Alert
    //     elevation={6}
    //     onClose={handleClose}
    //     severity={alert.snackType} //alert.snackType
    //     variant='filled'
    //     sx={{ width: '100%',
    //       '&.MuiAlert-filledError':{
    //         background: "#fdeded",
    //         color: "#B72136",
    //       },
    //       '&.MuiAlert-filledSuccess':{
    //         background: "#edf7ed",
    //         color: "#229A16",
    //       },
    //       '&.MuiAlert-filledInfo':{
    //         background: "#e5f6fd",
    //         color: "#0C53B7",
    //       },
    //       '&.MuiAlert-filledWarning':{
    //         background:"#fff4e5",
    //         color: "#F8A647"
    //       }
    //     }}
    //   >
    //     {alert.text}
    //   </Alert>
    //  </Snackbar>
  );
};

export default Snack;
