import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  useTheme,
} from "@mui/material";
import { CircleSharp, NotificationAddSharp } from "@mui/icons-material";
import DropdownSelect from "../DropdownSelect";
import ToolbarSelect from "./ToolbarSelect";
import { QuestionIcon } from "../SvgIcons";
import { ColorModeContext } from "../../App";
import { light } from "@mui/material/styles/createPalette";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducer";
import DropdownSingleSelect from "../DropdownSingleSelect";
import {
  getIntegrationStatusSectionList,
  getLogsListing,
  setQueryData,
} from "../../actions";
import { APP_ROUTES, ROLE, TIMEZONE } from "../../utils/constants";
import { stringAvatar } from "../../utils/utils";
import { StyledAvatar } from "../commonStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";

export const AppBarWrap = styled(AppBar)(({ theme }: { theme: any }) => ({
  background: theme.palette.background.paper,
  borderRadius: 2, //16
  boxShadow: "unset",
  color: theme.palette.text.primary,
}));
export const Legend = styled(CircleSharp)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    width: 12,
    height: 12,
    marginRight: 8,
    color: lgColor,
  })
);
export const CustomSelect = styled(Select)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    border: "unset",
    margin: "4px 8px",
  })
);

const ToolBar = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open, userInfo } = props;
  const theme = useTheme();
  const [service, setService] = React.useState("Khumbu");
  const [provider, setProvider] = React.useState("Providers");
  const [partner, setPartner] = React.useState("Partners");

  const { metadata } = useSelector((state: RootState) => ({
    metadata: state.logs.metadata.metadata,
  }));

  const { integrationStatusSectionList, isIntegrationStatusLoading } =
    useSelector((state: RootState) => ({
      integrationStatusSectionList:
        state.integrations.integrationStatusSectionList,
      isIntegrationStatusLoading: state.integrations.isIntegrationStatusLoading,
      // queryData: state.logs.queryData,
    }));

  React.useEffect(() => {
    dispatch(getIntegrationStatusSectionList());
  }, []);

  const queryData = {
    startTime: moment().clone().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    timeZone: TIMEZONE,
    page: 0,
    size: 20,
    searchText: "", // optional parameter, we can pass this to filter more specific data
    providerName: null, // optional parameter, we can pass this to filter more specific data
    partnerName: null, // optional parameter, we can pass this to filter more specific data
    type: null, // optional parameter, we can pass this to filter more specific data
    subType: null, // optional parameter, we can pass this to filter more specific data
    status: null,
    marketName: null,
  };

  const handleOnChange = (value: any, type: string) => {
    if (type === "Khumbu") {
      if (history.location.pathname.includes("logs")) {
        dispatch(getLogsListing(queryData, true));
      } else {
        history.push(APP_ROUTES.logs);
        // dispatch(getLogsListing(queryData, true));
        dispatch(setQueryData(queryData));
      }
    }
    if (type === "Providers") {
      setProvider(value);
      const payload = {
        ...queryData,
        providerName: [value.name],
      };
      if (history.location.pathname.includes("logs")) {
        dispatch(getLogsListing(payload, true));
      } else {
        history.push(APP_ROUTES.logs);
        // dispatch(getLogsListing(payload, true));
        dispatch(setQueryData(payload));
      }
      // dispatch(setQueryData(payload));
    } else if (type === "Partners") {
      setPartner(value);
      const payload = {
        ...queryData,
        partnerName: [value.name],
      };
      if (history.location.pathname.includes("logs")) {
        dispatch(getLogsListing(payload, true));
      } else {
        history.push(APP_ROUTES.logs);
        // dispatch(getLogsListing(payload, true))
        dispatch(setQueryData(payload));
      }
      // dispatch(setQueryData(payload));
    }
  };

  return (
    <AppBarWrap
      position="fixed"
      sx={{
        top: 16,
        right: 16,
        left: open ? 262 : 110,
        width: open ? "calc(100vw - 278px)" : "calc(100vw - 125px)",
      }}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            {userInfo && userInfo.role !== ROLE.ADMIN && (
              <Box display="flex" alignItems={"center"}>
                <StyledAvatar
                  {...stringAvatar(userInfo ? userInfo.providerName : "U")}
                  sx={{ mr: 1, fontSize: 15 }}
                  name={userInfo ? userInfo.providerName : "U"}
                />
                <Typography variant="h5" textTransform="capitalize">
                  {userInfo.providerName}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" justifySelf={"flex-end"}>
            {/* <Typography variant="subtitle2">Statuses:</Typography> */}

            {integrationStatusSectionList &&
              integrationStatusSectionList.map((list: any) => (
                <DropdownSelect
                  key={list?.sectionName}
                  value={list?.sectionName}
                  selectedColor={list?.sectionStatusColor}
                  handelChange={(e: any) =>
                    handleOnChange(e.target.value, list?.sectionName)
                  }
                  options={list?.integrationStatusList}
                />
              ))}
          </Box>
          {/* 
          <Box display="flex" alignItems="center">
            <Typography></Typography>
            <NotificationAddSharp htmlColor="#0066FF" />
          </Box> */}
        </Box>
      </Toolbar>
    </AppBarWrap>
  );
};

export default ToolBar;
