import React, { useEffect } from "react";
import "./App.css";
import { Router} from "react-router-dom";
import { Provider as StoreProvider,  } from "react-redux";
import RouteComponent from "./RouteComponent";
import { createBrowserHistory } from "history";
import { Theme } from '@mui/material/styles';
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import baseTheme from "./theme";
import ComponentsOverrides from "./theme/overrides";
import StylesProvider from './components/StylesProvider';
import { configureStore } from '../src/store';

// import { store } from "./redux/store";

const customHistory = createBrowserHistory();
const store = configureStore();

// it could be your App.tsx file or theme file that is included in your tsconfig.json
declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {
    customShadows: {
      z4: string;
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line no-unused-vars
export const ColorModeContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleColorMode: (mode: any) => {
    return localStorage.getItem('mode') as any;
  }
});

function App() {

  const [mode, setMode] = React.useState<'light' | 'dark'>(
    (localStorage.getItem('mode') as any) || 'light'
  );

  useEffect(() => {
    if (!localStorage.getItem('mode')) {
      localStorage.setItem('mode', 'light');
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: (mode: any) => {
        setMode(() => mode);
      }
    }),
    []
  );
  const theme = React.useMemo(() => {
    const getPalette = baseTheme.getPalette;
    const createCustomShadow = baseTheme.createCustomShadow;
    const theme = createTheme({
      ...baseTheme,
      ...{ palette: getPalette(mode), customShadows: createCustomShadow(mode) }
    });
    theme.components = ComponentsOverrides(theme);
    return createTheme({
      ...baseTheme,
      ...{ palette: getPalette(mode) },
    });
  }, [mode]);
  
  
  return (
    <StoreProvider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
        <CssBaseline />
          <StylesProvider>
            <Router history={customHistory}>
              <RouteComponent />
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StoreProvider>
    
  );
}

export default App;
