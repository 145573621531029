import moment from "moment-timezone";

export const APP_ROUTES = {
  dashboard: "/dashboard",
  status: "/status",
  analytics: "/analytics",
  logs: "/logs",
  integrations: "/integrations",
  newIntegration: "/integrations/new",
  integrationSettings: "/integrations/settings",
  manageProviders: "/integrations/providers",
  manageStore: "/integrations/stores",
  managePartner: "/integrations/partners",
  providerConfig: "/integrations/provider",
  storeConfig: "/integrations/store/new",
  storeConfigId: "/integrations/setting/store",
  partnerConfig: "/integrations/partner",
  gettingStarted: "/gettingStarted",
  invoices: "/invoices",
  users: "/users",
  settings: "/settings",
  support: "/support",
  // tenantDataIngested: '/tenants/:tenantId/data-ingested',
  // tenantUserAccount: '/tenants/:tenantId/user-accounts',
  login: "/login",
};

export enum FILTER_TYPE {
  type = "KhumbuService",
  subType = "API",
  providersName = "Providers",
  partnersName = "Partners",
  status = "Status",
}

export const TIMEZONE = moment.tz.guess();

export const VIEWMODE = {
  LIGHT: "light",
  DARK: "dark",
};

export const INTEGRATIONS_STEPS_ENUM = {
  SELECT_PROVIDER: "SELECT_PROVIDER",
  SELECT_PARTNER: "SELECT_PARTNER",
  PROVIDER_CONFIG: "PROVIDER_CONFIG",
  PARTNER_CONFIG: "PARTNER_CONFIG",
};

export const INTEGRATIONS_STEPS: any = {
  SELECT_PROVIDER: {
    step: "Select Povider",
    nextStepText: "Next: Select Partner",
    nextStep: "SELECT_PARTNER",
  },
  SELECT_PARTNER: {
    step: "Select Partner",
    nextStepText: "Next: Market Config",
    prevStep: "SELECT_PROVIDER",
    nextStep: "MARKET_CONFIG",
  },
  MARKET_CONFIG: {
    step: "Market Config",
    prevStep: "SELECT_PARTNER",
    nextStepText: "Confirm",
  },
};

export const initialAlert = {
  title: "",
  text: "",
  buttonText: "",
  icon: null,
  onHandlePress: null,
  component: null,
  buttonText2: "",
  onHandlePress2: null,
  subtitle: "",
  snackType: undefined,
  snack: false,
  noTextMargin: false,
  showText: false,
  notCloseOnSubmit: false,
  inputType: "text",
  buttonWrapperStyle: {},
  buttonStyle: {},
  allowedText: "",
  inputText: "",
  onModelClose: null,
};

export const STATUS_COLOR: any = {
  GREEN: "#07DB3E",
  ORANGE: "#F8A647",
  RED: "#F71631",
};

export const errorMessages: any = {
  204: "I’m performing deep research on your content to ensure I can be as valuable to you as possible! Please check back here.",
  400: `Sorry I could not find enough content to populate the data here.`,
  500: `We are experiencing a brief interruption. We promise to be back shortly and apologize for the inconvenience. Thank you for your patience`,
  404: `Sorry I could not find enough content to populate the data here.`,
};

export enum ROLE {
  ADMIN = "ADMIN",
  DEVELOPER = "DEVELOPER",
  SUPPORT = "SUPPORT",
  PROVIDER_ADMIN = "PROVIDER_ADMIN",
  PROVIDER_DEV = "PROVIDER_DEV",
  PROVIDER_SUPPORT = "PROVIDER_SUPPORT",
}

export enum INTEGRATIONSTATUS {
  PUBLISHED = "PUBLISHED",
  UNDERREVIEW = "UNDERREVIEW",
}
