import { History } from 'history'; 

import { MyThunkAction } from "../types";
import { get, patch, post, } from "./common";
import { sendAlert } from "./UserAction";
import { APP_ROUTES } from '../utils/constants';

export const PARTNER_ALERT = "PARTNER_ALERT";
export const GET_PARTNER_LOADING = "GET_PARTNER_LOADING";
export const GET_PARTNER_SUCESS = "GET_PARTNER_SUCESS";
export const GET_PARTNER_ALERT = "GET_PARTNER_ERROR";

export const CLEAN_PARTNER_METADATA = "CLEAN_PARTNER_METADATA";
export const GET_PARTNER_METADATA_LOADING = "GET_PARTNER_METADATA_LOADING";
export const GET_PARTNER_METADATA_SUCCESS = "GET_PARTNER_METADATA_SUCCESS";
export const GET_PARTNER_METADATA_ALERT = "GET_PARTNER_METADATA_ALERT";

export const CLEAN_MARKET_TEMPLATE = "CLEAN_MARKET_TEMPLATE";
export const GET_MARKET_TEMPLATE_LOADING="GET_MARKET_TEMPLATE_LOADING"
export const GET_MARKET_TEMPLATE_SUCCESS="GET_MARKET_TEMPLATE_SUCCESS"

export const CLEAN_STORE_TEMPLATE = "CLEAN_STORE_TEMPLATE";
export const GET_STORE_TEMPLATE_LOADING="GET_STORE_TEMPLATE_LOADING"
export const GET_STORE_TEMPLATE_SUCCESS="GET_STORE_TEMPLATE_SUCCESS"



export const getPartners =
  (): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: `${GET_PARTNER_LOADING}` });

    try {
      const response = await get(`/integrations/partner/metadata`);
      dispatch({
        type: `${GET_PARTNER_SUCESS}`,
        payload: response,
      });
    } catch (error: any) {
      dispatch({
        type: `${GET_PARTNER_ALERT}`,
        payload: error.message || "Failed to fetch partner",
      });
    }
  };

  export const getMarketConfigTemplate =
  (templateType:string='marketConfigTemplate'): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: `${GET_MARKET_TEMPLATE_LOADING}` });

    const response = await get(`/integrations/config/metadata/template/${templateType}
      `);
    
      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Network error, please try again",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          }),
        );
        return { status: 400,};
      }
      else{
        dispatch({
          type: GET_MARKET_TEMPLATE_SUCCESS,
          payload: response,
        });

        return {status:200}

      }
  };
  export const getStoreConfigTemplate =
  (templateType:string='storeConfigTemplate'): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: `${GET_STORE_TEMPLATE_LOADING}` });

    const response = await get(`integrations/config/metadata/template/${templateType}`);
        
      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Network error, please try again",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          }),
        );
        return { status: 400,};
      }
      else{
        dispatch({
          type: GET_STORE_TEMPLATE_SUCCESS,
          payload: response,
        });

        return {status:200}

      }
  };


  export const getPartnerMetadata = (ID:string,history:History): MyThunkAction => async (dispatch: any) => {
    dispatch({ type: GET_PARTNER_METADATA_LOADING });
    const response = await get(`/integrations/partner/${ID}`);
    if(response?.error){
      dispatch(
        sendAlert({
          text: "Network error, please try again",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        }),
      );
      history.push(APP_ROUTES.manageProviders)
      return { status: 400,};
    }
    else{
      dispatch({ type: GET_PARTNER_METADATA_SUCCESS, payload: response });
      return { status: 200,};
    }
    
  };

   export const CreateNewPartnerConfig = (data:any,history: History): MyThunkAction => async (dispatch: any) => {
  
       const response= await post(`/integrations/partner/create`,data)
       
           if (response?.error) {
             dispatch(
               sendAlert({
                 text: "Network error, please try again",
                 snack: true,
                 snackTimeout: 4000,
                 snackType: "error",
               }),
             );
             return { status: 400,};
           }
       else{
      dispatch(
        sendAlert({
          text: response?.message,
          snack: true,
          snackTimeout: 4000,
          snackType: "success",
        }),
      );
      history.push(APP_ROUTES.managePartner);
      dispatch(getPartners())
     return { status: 200 };
     }
      
          
    };
  
    export const UpdatePartnerConfig = (data:any,history: History): MyThunkAction => async (dispatch: any) => {

      const response= await patch(`/integrations/partner/update`,data)
        if (response?.error) {
            dispatch(
              sendAlert({
                text: "Network error, please try again",
                snack: true,
                snackTimeout: 4000,
                snackType: "error",
              }),
            );
            return { status: 400,};
          }
      else{
      dispatch(
    sendAlert({
      text: response?.message,
      snack: true,
      snackTimeout: 4000,
      snackType: "success",
    }),
  );
  dispatch(getPartners())
  history.push(APP_ROUTES.managePartner);
  return { status: 200 };

}

   };

   export const CleanPartnerMetadata = (): MyThunkAction => async (dispatch: any) => {
   return dispatch({ type: CLEAN_PARTNER_METADATA });
  };

  export const CleanMarketConfigTemplate = (): MyThunkAction => async (dispatch: any) => {
    return dispatch({ type: CLEAN_MARKET_TEMPLATE });
   };
   export const CleanStoreConfigTemplate = (): MyThunkAction => async (dispatch: any) => {
    return dispatch({ type: CLEAN_STORE_TEMPLATE });
   };

