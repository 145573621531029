import { CLEAN_MARKET_CONFIG, MARKET_CONFIG_ALERT, MARKET_CONFIG_LOADING, MARKET_CONFIG_SUCCESS, NEW_MARKET_CONFIG_LOADING, NEW_MARKET_CONFIG_SUCESS } from "../actions/MarketAction";

interface PartnerState {
  marketConfig:any
  alert: string | null;
  isLoading:boolean,
  newMarketConfig:any
}
const initialState: PartnerState = {
  marketConfig :null,
  isLoading: true,
  alert: null,
  newMarketConfig:null
};

export const marketReducer = (
  state = initialState,
  action: any
): PartnerState => {
  switch (action.type) {
    
    case MARKET_CONFIG_LOADING:
      return {
        ...state,
        isLoading: true,
        alert: null,
      };

    case MARKET_CONFIG_SUCCESS:
      return {
        ...state,
        marketConfig: action.payload || [],
        isLoading: false,
        alert: null,
      };

      case NEW_MARKET_CONFIG_LOADING:
        return {
          ...state,
          isLoading: true,
          alert: null,
        };
  
      case NEW_MARKET_CONFIG_SUCESS:        
        return {
          ...state,
          newMarketConfig: action.payload,
          isLoading: false,
          alert: null,
        };

    case CLEAN_MARKET_CONFIG:
      return {
        ...state,
        marketConfig: null,
        alert: null,
      };

    default:
      return state;
  }
};
