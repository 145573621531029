import { History } from 'history'; 
import { MyThunkAction } from "../types";
import { get, getWithHeader, patch, postWithHeader } from "./common";
import { sendAlert } from "./UserAction";
import { APP_ROUTES } from '../utils/constants';

export const PROVIDER_ALERT = "PROVIDER_ALERT";

export const GET_PROVIDERS = "SET_PROVIDER_INFO";
export const GET_PROVIDERS_LOADING="SET_PROVIDER_INFO_LOADING"
export const GET_PROVIDERS_SUCCESS="SET_PROVIDER_INFO_SUCCESS"
export const GET_PROVIDERS_ERROR="SET_PROVIDER_INFO_ERROR"

export const GET_PROVIDER_CONFIG = "GET_PROVIDER_CONFIG";
export const GET_PROVIDER_CONFIG_LOADING = "GET_PROVIDER_CONFIG_LOADING";
export const GET_PROVIDER_CONFIG_SUCCESS = "GET_PROVIDER_CONFIG_SUCCESS";
export const GET_PROVIDER_CONFIG_ERROR = "GET_PROVIDER_CONFIG_ERROR";


export const GET_PROVIDER_CONFIG_MEATA_DATA = "GET_PROVIDER_CONFIG_MEATA_DATA";
export const GET_PROVIDER_CONFIG_MEATA_DATA_LOADING = "GET_PROVIDER_CONFIG_MEATA_DATA_LOADING";
export const GET_PROVIDER_CONFIG_MATA_DATA_SUCCESS = "GET_PROVIDER_CONFIG_MATA_DATA_SUCCESS";
export const GET_PROVIDER_CONFIG_MEATA_DATA_ERROR = "GET_PROVIDER_CONFIG_MEATA_DATA_ERROR";
export const CLEAN_PROVIDER_METADATA="CLEAN_PROVIDER_METADATA";

export const getProviders =
  (queryString?: any, forceReset?: boolean): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: `${GET_PROVIDERS_LOADING}` });

    try {
      const response = await getWithHeader(`/integrations/provider`,queryString);
      dispatch({
        type: `${GET_PROVIDERS_SUCCESS}`,
        payload: response,
      });
    } catch (error: any) {
      dispatch({
        type: `${GET_PROVIDERS_ERROR}`,
        payload: error.message || "Failed to fetch providers",
      });
    }
  };

  export const getProviderConfigMeataData =(history:History): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: `${GET_PROVIDER_CONFIG_MEATA_DATA_LOADING}` });
    const response = await get(`/integrations/config/metadata`);
    if(response?.error){
      sendAlert({
        text: "Network error, please try again",
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      })
      history.push(APP_ROUTES.manageProviders)
      return {status:200}
    }
    else{
      dispatch({
        type: GET_PROVIDER_CONFIG_MATA_DATA_SUCCESS,
        payload: response,
      });

      return {status:200}
    }
   
  };

  export const getProviderConfig = (queryString?: any,history?:History,isProviderAdmin?:boolean): MyThunkAction => async (dispatch: any) => {

    dispatch({ type: GET_PROVIDER_CONFIG_LOADING });
    const response = await get(`/integrations/provider/config/${queryString}`);  
    console.log(response);
      
      if(response.error){
        dispatch(
          sendAlert({
            snack: true,
            text: "Network error, please try again",
            snackTimeout: 4000,
            snackType: "error",
          })
        )
        dispatch({
          type: GET_PROVIDER_CONFIG_ERROR,
          payload: response, 
        });
        if(history)
          if(isProviderAdmin){
            history.push(APP_ROUTES.integrations)
          }
          else{
        history.push(APP_ROUTES.manageProviders)

          }
        return { status: 400,};
      } 
      else
      {
        dispatch({
          type: GET_PROVIDER_CONFIG_SUCCESS,
          payload: response, 
        });
        return { status: 200,};
      }
     
  
  }

  export const CreateNewProviderConfig = (data:any,headerData:string,history:History,isProviderAdmin:boolean,handleOpen:any): MyThunkAction => async (dispatch: any) => {

    const headers = { Username: headerData };
  
     const response=  await postWithHeader(`/integrations/provider/config`,data,{headers})

         if (response?.error) {
           dispatch(
             sendAlert({
               text: "Duplicate Provider Name!",
               snack: true,
               snackTimeout: 4000,
               snackType: "error",
             }),
           );
           return { status: 400,};
         }

      dispatch(
            sendAlert({
              text: "Provider Created successfully.",
              snack: true,
              snackTimeout: 4000,
              snackType: "success",
            }),
          );
          dispatch(getProviders())
          if(!isProviderAdmin){
            history.push(APP_ROUTES.manageProviders);
          }
          else{
            handleOpen()
          }
    return { status: 200 };
  };

  export const UpdateProviderConfig = (data:any,history:History,isProviderAdmin:boolean,handleOpen:any): MyThunkAction => async (dispatch: any) => {
    
        const response= await patch(`/integrations/provider/config`,data)
   
            if (response?.error) {
              dispatch(
                sendAlert({
                  text: "Network error, please try again",
                  snack: true,
                  snackTimeout: 4000,
                  snackType: "error",
                }),
              );
              return { status: 400,};
            }
   
         dispatch(
               sendAlert({
                 text: "Provider update successfully.",
                 snack: true,
                 snackTimeout: 4000,
                 snackType: "success",
               }),
             );
             dispatch(getProviders())
             if(!isProviderAdmin){
              history.push(APP_ROUTES.manageProviders);
            }
            else{
              handleOpen()
              history.push(APP_ROUTES.integrations);
            }
      return { status: 200 };      
  };
  

  export const CleanProviderMetadata = (): MyThunkAction => async (dispatch: any) => {
     return dispatch({ type: CLEAN_PROVIDER_METADATA });
    };
  


