import { ActionType } from "./types";
import * as actionTypes from "../actions";
import moment from "moment";
import { TIMEZONE } from "../utils/constants";

type LogsTypes = {
  logs: [],
  logsListing:any,
  apiDetails: {},
  apiResponse: [],
  apiData: {},
  metadata:[],
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  loading: boolean;
  hasMore: boolean;
  queryData:{};
  isLoading:boolean;
  isLogsLoading:boolean;

}

const initialState: LogsTypes = {
  logs: [],
  logsListing: [],
  apiDetails:{},
  apiResponse: [],
  apiData: {},
  metadata:[],
  page: 1,
  pageSize: 20,
  totalPages: 0,
  totalRecords: 0,
  loading: false,
  hasMore: true,
  queryData:{
    startTime:moment().clone().startOf('day').format("YYYY-MM-DDTHH:mm:ss"),
    endTime:moment().format("YYYY-MM-DDTHH:mm:ss"),
    timeZone:TIMEZONE,
   	page:0,          
   	size:20,  
   	searchText: "",    // optional parameter, we can pass this to filter more specific data
   	providerName:null, // optional parameter, we can pass this to filter more specific data
   	partnerName:null, // optional parameter, we can pass this to filter more specific data
   	type:null,  // optional parameter, we can pass this to filter more specific data
   	subType:null , // optional parameter, we can pass this to filter more specific data
    status:null,
    marketName:null,
    
  },
  isLoading:false,
  isLogsLoading:false,
  
};

const logs = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_LOGS_LIST_LOAD: {
      const { response =[], queryString,forceReset } = action.payload;
      
      return {
        ...state,
        logs: action.payload.response,
        // logsListing:  [...( forceReset?[] : action.payload.response.metricsListResponses)],
        logsListing:  response.metricsListResponses,
        // contractList: [...(forceReset ? [] : state.contractList), ...contractResponses],
        page: action.payload.response.currentPage,
        pageSize:action.payload.response.pageSize,
        totalPages:action.payload.response.totalPages,
        totalRecords: action.payload.response.totalRecords,
        hasMore: action.payload.response.totalRecords > action.payload.response.pageSize,
        queryData:  {...(forceReset?{}: state.queryData ), ...queryString}
        // queryData:  action.payload.queryString
      };
    }
    case actionTypes.GET_API_DETAILS: {   
      const { response =[],forceReset } = action.payload;  
      const apiResponseData = Array.isArray(response.apiResponse) ? response.apiResponse : [];
 
      return {

        ...state,
        apiDetails: response,
        apiResponse:[...(forceReset ? [] : state.apiResponse), ...apiResponseData]

        // apiResponse: action.payload?.apiResponse,
      };
    }
    case actionTypes.ON_LOGS_LOADING_START:{
      return{
        ...state,
        isLogsLoading: action.payload
      }
    }
    case actionTypes.ON_LOGS_LOADING_FINISHED:{
      return{
        ...state,
        isLogsLoading: action.payload
      }
    }
    case actionTypes.ON_LOADING_START:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.ON_LOADING_FINISHED:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.CLEAR_API_DETAILS:{
      return {
        ...state,
        apiDetails: [],
        apiResponse: [],
      };
    }
    case actionTypes.SET_QUERY_DATA:{
      const queryString = action.payload
      return {
        ...state,
        queryData:queryString
      };
    }
    case actionTypes.GET_MEAT_DATA: {      
      return {
        ...state,
        metadata: action.payload
      };
    }
    // case 'SET_QUERY_DATA':
      
    //   return { 
    //     ...state, 
    //     loading: false, error: action.payload ,
    //     queryData: {...state.queryData, action.payload}
    //   };
    default: {
      return state;
    }
  }
};

export default logs;
