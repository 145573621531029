import { CLEAN_MARKET_TEMPLATE, CLEAN_PARTNER_METADATA, CLEAN_STORE_TEMPLATE, GET_MARKET_TEMPLATE_LOADING, GET_MARKET_TEMPLATE_SUCCESS, GET_PARTNER_ALERT, GET_PARTNER_LOADING, GET_PARTNER_METADATA_ALERT, GET_PARTNER_METADATA_LOADING, GET_PARTNER_METADATA_SUCCESS, GET_PARTNER_SUCESS, GET_STORE_TEMPLATE_LOADING, GET_STORE_TEMPLATE_SUCCESS, PARTNER_ALERT } from "../actions";
interface PartnerState {
  partners: any[];
  partnerMetadataLists: any;
  isLoading: boolean;
  alert: string | null;
  marketConfigTemplate:any,
  storeConfigTemplate:any,
  isStoreTemplateConfigLoading:boolean,
  isMarketTemplateConfigLoading:boolean,
}
const initialState: PartnerState = {
  partners: [],
  isLoading: true,
  alert: null,
  partnerMetadataLists: null,
  marketConfigTemplate:null,
  isStoreTemplateConfigLoading:false,
  isMarketTemplateConfigLoading:false,
  storeConfigTemplate:null
};

export const partnerReducer = (
  state = initialState,
  action: any
): PartnerState => {
  switch (action.type) {
    case GET_PARTNER_LOADING:
      return {
        ...state,
        isLoading: true,
        alert: null,
      };

    case GET_PARTNER_SUCESS:
      return {
        ...state,
        partners: action.payload.partnerMetadataLists,
        isLoading: false,
        alert: null,
      };

      case GET_MARKET_TEMPLATE_LOADING:
      return {
        ...state,
        isMarketTemplateConfigLoading: true,
        alert: null,
      };

    case GET_MARKET_TEMPLATE_SUCCESS:
      return {
        ...state,
        marketConfigTemplate:action.payload,
        isMarketTemplateConfigLoading: false,
        alert: null,
      };

      case GET_STORE_TEMPLATE_LOADING:
        return {
          ...state,
          isStoreTemplateConfigLoading: true,
          alert: null,
        };
  
      case GET_STORE_TEMPLATE_SUCCESS:
        return {
          ...state,
          storeConfigTemplate:action.payload,
          isStoreTemplateConfigLoading: false,
          alert: null,
        };

    case GET_PARTNER_ALERT:
      return {
        ...state,
        isLoading: false,
        alert: action.payload,
      };

    case GET_PARTNER_METADATA_LOADING:
      return {
        ...state,
        isLoading: true,
        alert: null,
      };

    case GET_PARTNER_METADATA_SUCCESS:
      return {
        ...state,
        partnerMetadataLists: action.payload || [],
        isLoading: false,
        alert: null,
      };

    case PARTNER_ALERT: {
      return {
        ...state,
        alert: action.payload,
      };
    }

    case GET_PARTNER_METADATA_ALERT:
      return {
        ...state,
        isLoading: false,
        alert: action.payload,
      };

    case CLEAN_PARTNER_METADATA:
      return {
        ...state,
        partnerMetadataLists: null,
        alert: null,
      };

      case CLEAN_STORE_TEMPLATE:
        return {
          ...state,
          storeConfigTemplate: null,
          alert: null,
        };

        case CLEAN_MARKET_TEMPLATE:
          return {
            ...state,
            marketConfigTemplate: null,
            alert: null,
          };

    default:
      return state;
  }
};
