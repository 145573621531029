import { post } from "./common";
import config from "../config";
import { MyThunkAction } from "../types";



export const ON_DASHBOARD_LOAD = "ON_DASHBOARD_LOAD";
export const ON_STATS_LOADING_START = "ON_LOADING_START";
export const ON_STATS_LOADING_FINISHED = "ON_LOADING_FINISHED";

export const getDashboardStats = (queryString?:any): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: ON_STATS_LOADING_START,
    payload: true,
  });

  const response = await post(`/metrics/stats`,queryString)

  dispatch({
    type: ON_DASHBOARD_LOAD,
    payload: response,
  });

  dispatch({
    type: ON_STATS_LOADING_FINISHED,
    payload: false,
  });

  return { status: 200 };
};



