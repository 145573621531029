import { Box } from "@mui/material";
import React, { lazy, Suspense, useEffect } from "react";
import Layout from "./components/Layout";
// import { PrivateRoute } from './PrivateRoute';
import { APP_ROUTES } from "./utils/constants";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { useDispatch } from "react-redux";

const Dashboard = lazy(() => import("./views/Dashboard"));
const Logs = lazy(() => import("./views/Logs"));
const Status = lazy(() => import("./views/Status"));
const Analytics = lazy(() => import("./views/Analytics"));
const Billing = lazy(() => import("./views/Billing"));
const Integrations = lazy(() => import("./views/Integrations"));
const NewIntegration = lazy(
  () => import("./views/Integrations/NewIntegration")
);
const Support = lazy(() => import("./views/Support"));
const Settings = lazy(() => import("./views/Settings"));
const Login = lazy(() => import("./Login"));
const ManagerProvider = lazy(() => import("./views/Integrations/providers"));
const ProviderConfig = lazy(
  () => import("./views/Integrations/providers/ConfigSetting")
);
const ManagerPartner = lazy(() => import("./views/Integrations/partners"));
const PartnerConfig = lazy(
  () => import("./views/Integrations/partners/ConfigSetting")
);
const ManageStore = lazy(() => import("./views/Integrations/Store"));
const StoreConfig = lazy(
  () => import("./views/Integrations/Store/ConfigSetting")
);

type RouteComponentProps = {};

const RouteComponent: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isloggedIn");
    const expiration = localStorage.getItem("expiration");

    if (isLoggedIn && expiration) {
      if (Date.now() > parseInt(expiration)) {
        // Session expired
        localStorage.removeItem("isloggedIn");
        localStorage.removeItem("expiration");
        // dispatch({type: 'LOGOUT'});
        history.push("/login"); // Redirect to login
      }
    } else {
      dispatch({ type: "LOGOUT" });
    }
  }, [dispatch, history]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Layout>
          <Suspense
            fallback={
              <Box position="absolute" top="50%" left="50%" zIndex={9999}>
                loading...
              </Box>
            }
          >
            <Switch>
              {/* Analytics screen urls */}
              <Redirect exact from="/" to={APP_ROUTES.dashboard} />
              <Route path={APP_ROUTES.login} component={Login} />
              <PrivateRoute
                exact
                path={APP_ROUTES.dashboard}
                component={Dashboard}
              />
              <PrivateRoute exact path={APP_ROUTES.status} component={Status} />
              <PrivateRoute exact path={APP_ROUTES.logs} component={Logs} />
              <PrivateRoute
                exact
                path={APP_ROUTES.analytics}
                component={Analytics}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.invoices}
                component={Billing}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.integrations}
                component={Integrations}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.newIntegration}/:integrationId?`}
                component={NewIntegration}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.integrationSettings}/:id`}
                component={NewIntegration}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.support}/:ticketId?`}
                component={Support}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.manageProviders}
                component={ManagerProvider}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.providerConfig}/new`}
                component={ProviderConfig}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.providerConfig}/:integrationId?`}
                component={ProviderConfig}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.settings}
                component={Settings}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.managePartner}
                component={ManagerPartner}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.partnerConfig}/new`}
                component={PartnerConfig}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.partnerConfig}/:id?`}
                component={PartnerConfig}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.manageStore}`}
                component={ManageStore}
              />
              <PrivateRoute
                exact
                path={APP_ROUTES.storeConfig}
                component={StoreConfig}
              />
              <PrivateRoute
                exact
                path={`${APP_ROUTES.storeConfigId}/:marketIntegrationId?/:storeIntegrationId?`}
                component={StoreConfig}
              />
              {/* <PrivateRoute
                exact
                path={`${APP_ROUTES.storeConfigId}/:integrationId?`}
                component={StoreConfig}
              /> */}
              {/* <Redirect from="/" to={APP_ROUTES.dashboard} /> */}
            </Switch>
          </Suspense>
        </Layout>
      </BrowserRouter>
    </React.Fragment>
  );
};
export default RouteComponent;
