import { AlertType } from "./types";
import { initialAlert } from "../utils/constants";
import {
  GET_STORE_LOADING,
  GET_STORE_SUCESS,
  STORE_ALERT,
  GET_STORE_METADATA_LOADING,
  GET_STORE_METADATA_SUCESS,
  CLEAN_PROVIDER_METADATA,
  GET_STOREINTIGRATION_CONFIG_SUCESS,
  CLEAN_STORE_METADATA,
  GET_STOREINTIGRATION_CONFIG_ERROR,
} from "../actions";
import { ConfigMetadataWrapper, StoreIntegration } from "../types/store";

interface StoreState {
  integrationList: StoreIntegration[];
  alert: AlertType;
  storeMetadata: ConfigMetadataWrapper;
  isLoading: boolean;
  error: string | null;
  storeIntegrationConfig:ConfigMetadataWrapper;
}

const initialState: StoreState = {
  integrationList: [],
  alert: initialAlert,
  storeMetadata: {
    configMetadata: {
      configType: "",
      providerName: "",
      sections: [],
    },
  },
  isLoading: false,
  error: null,
  storeIntegrationConfig: {
    configMetadata: {
      configType: "",
      providerName: "",
      sections: [],
    },
  },
};

export const storeReducer = (
  state = initialState,
  action: any
): StoreState => {
  switch (action.type) {
    case GET_STORE_LOADING:
    case GET_STORE_METADATA_LOADING:
      return { ...state, isLoading: true, error: null };

    case GET_STORE_SUCESS:
      return {
        ...state,
        integrationList: action.payload,
        isLoading: false,
        error: null,
      };

    case GET_STORE_METADATA_SUCESS:
      return {
        ...state,
        storeMetadata: action.payload,
        isLoading: false,
        error: null,
      };

    case GET_STOREINTIGRATION_CONFIG_SUCESS:
    return {
      ...state,
      storeIntegrationConfig: action.payload,
      isLoading: false,
      error: null,
    };
    case GET_STOREINTIGRATION_CONFIG_ERROR:
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };

    case STORE_ALERT:
      return { ...state, alert: action.payload };

    case CLEAN_STORE_METADATA:
      return { ...state, storeMetadata: { configMetadata: { configType: "", providerName: "", sections: [] } } };

    default:
      return state;
  }
};
