import * as React from "react";
import { MenuItem, Select, styled, Typography } from "@mui/material";
import {
  CircleSharp,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { STATUS_COLOR } from "../utils/constants";

export const Legend = styled(CircleSharp)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    width: 12,
    height: 12,
    marginRight: 8,
    color: lgColor,
  })
);
export const CustomSelect = styled(Select)(
  ({ theme, lgColor }: { theme?: any; lgColor: string }) => ({
    border: "unset",
    margin: "4px 8px",
  })
);
export const MenuList = styled(MenuItem)(({ theme }: { theme?: any }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.text.primary,
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

type DropdownSelectProps = {
  classes?: any;
  value?: any;
  options?: any;
  handelChange?: any;
  isLegend?: boolean;
  width?: any;
  border?: string;
  selectedColor?:string
};

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  classes,
  value,
  options,
  handelChange,
  isLegend = true,
  width,
  border,
  selectedColor = "green"
}) => {
  const theme: any = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  
  return (
    <Select
      value={value}
      variant="standard"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handelChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      IconComponent={() =>
        open ? (
          <KeyboardArrowUp onClick={handleClose} />
        ) : (
          <KeyboardArrowDown onClick={handleOpen} />
        )
      }
      disableUnderline
      sx={{
        fontSize: 14,
        fontWeight: 500,
        mx: 1,
        width: width,
        border: border,
        padding: "0px 8px 0px 16px",
        color: theme.palette.text.drawer,
        "& .MuiSelect-select": {
          paddingRight: `12px !important`,
          minWidth: "0px !important",
          color: theme.palette.text.primary,
        },
      }}
      renderValue={(selected: any) => {
        return (
          <Typography
            variant="body1"
            sx={{ display: "flex", fontSize: 14, alignItems: "center" }}
          >
            {isLegend && <Legend lgColor={STATUS_COLOR[selectedColor]} />}
            {selected}
          </Typography>
        );
      }}
    >
      {options?.map((items: any, index: number) => { 
        return (
          <MenuList key={index} value={items} selected={value === items}>
            {isLegend && <Legend lgColor={STATUS_COLOR[items?.statusColor]} />}
            {items?.name? items?.name : items}
          </MenuList>
        );
      })}
    </Select>
  );
};

export default DropdownSelect;
