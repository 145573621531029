import { get, post, put, remove } from "./common";
import { MyThunkAction } from "../types";
import { AlertType } from "../reducer/types";
import { initialAlert } from "../utils/constants";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { isEmpty } from "lodash";



export const GET_USER_INFO = "SET_USER_INFO";
export const USER_LOADING_START = "USER_LOADING_START";
export const USER_LOADING_FINISHED = "USER_LOADING_FINISHED";
export const GET_USER_ROLES = "SET_USER_ROLES";
export const SET_NEW_USER_INFO = "SET_NEW_USER_INFO";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const USER_ALERT = "USER_ALERT";
export const SET_USER_ALERT_INPUT = "SET_USER_ALERT_INPUT";
export const SHOW_ALERT_STATUS = "SHOW_ALERT_STATUS";
export const ON_USERS_LOADING_START = "ON_USERS_LOADING_START";
export const ON_USERS_LOADING_FINISHED = "ON_USERS_LOADING_FINISHED";
export const SET_VIEW_MODE = "SET_VIEW_MODE";


// export const setAnalyticsQueryData = (queryString?:any,forceReset?:boolean) => ({
//   type:SET_ANALYTICS_QUERY_DATA,
//   payload: queryString
// });
export const setAlertStatus = (payload: boolean) => ({
  type: SHOW_ALERT_STATUS,
  payload,
});

export const sendAlert = (payload: Partial<AlertType>) => ({
  type: USER_ALERT,
  payload,
});

export const resetAlert = (): MyThunkAction => async (dispatch) => {
  dispatch({ type: USER_ALERT, payload: initialAlert });
  dispatch({ type: SET_USER_ALERT_INPUT, payload: "" });
};

export const setViewMode = (payload: string) => ({
  type: SET_VIEW_MODE,
  payload,
});

export const getUserInfo = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  dispatch({
    type: USER_LOADING_START,
    payload: true
  });

  const response = await get(`/users/profile`)

  dispatch({
    type: GET_USER_INFO,
    payload: response
  });

  dispatch({
    type: USER_LOADING_FINISHED,
    payload: false
  });

  return { status: 200 };
};

export const UpdateUserInfo = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {
  

  const response = await put(`/users/profile`,queryString)

  if (response?.error) {
    dispatch(
      sendAlert({
        text: "Facing Issue",
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      }),
    );
    return { status: 400};
  }
  // else if(!isEmpty(response) || !response?.error){
    dispatch(
      sendAlert({
        text: "User updated successfully.",
        snack: true,
        snackTimeout: 4000,
        snackType: "success",
      }),
    );
    dispatch({
      type: GET_USER_INFO,
      payload: response
    });
  // }
  return { status: 200 };
};

export const getUserRoles = (forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

    const response = await get(`/roles`)
  
    dispatch({
      type: GET_USER_ROLES,
      payload: response
    });  
    return { status: 200 };
};
export const getUsersList= (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  dispatch({ type:ON_USERS_LOADING_START, payload:true });

  const response = await get(`/users${isEmpty(queryString)?"":`?q=${queryString}`}`)
  dispatch({
    type: GET_USERS_LIST,
    payload: {
      forceReset: forceReset,
      response:response
    }
  });
  dispatch({ type:ON_USERS_LOADING_FINISHED, payload:false });
  return { status: 200 };
};

export const CreateNewUser = (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

    const response = await post(`/users/register`,queryString)
    
    if(response?.error){
      dispatch(
        sendAlert({
          text: response?.error.message,
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        }),
      );
    }
    else{
      dispatch(
        sendAlert({
          text: "User Added successfully.",
          snack: true,
          snackTimeout: 4000,
          snackType: "info",
        }),
      );
    }
    
    dispatch(getUsersList())
    dispatch({
      type: SET_NEW_USER_INFO,
      payload: {
        queryString:queryString,
        forceReset: forceReset,
        response:response
      }
    });  
    return { status: 200 };
};

export const updateUser= (username?:string,payload?:any): MyThunkAction => async (dispatch: any) => {

  const response = await put(`/users/${username}`,payload)
  if (response?.error) {
    dispatch(
      sendAlert({
        text: "Facing Issue",
        snack: true,
        snackTimeout: 4000,
        snackType: "error",
      }),
    );
    return { status: 400};
  }
  else if(!isEmpty(response) || !response?.error){
    dispatch(
      sendAlert({
        text: "User updated successfully.",
        snack: true,
        snackTimeout: 4000,
        snackType: "success",
      }),
    );
    dispatch(getUsersList());
  }
  return { status: 200 };
};

export const removeUser= (queryString?:any,forceReset?:boolean): MyThunkAction => async (dispatch: any) => {

  const response = await remove(`/users/${queryString}`)
  if (response?.error) {
    dispatch(
      sendAlert({
        text: "Facing Issue",
        snack: true,
        snackTimeout: 6000,
        snackType: "error",
      }),
    );
    return { status: 400,};
  }
  else{
    dispatch(
      sendAlert({
        text: "User Deleted Successfully",
        snack: true,
        snackTimeout: 6000,
        snackType: "success",
      }),
    )
  };
  dispatch(getUsersList());
  return { status: 200 };
};





