import { styled} from '@mui/material/styles';
import { Avatar, Button, ButtonGroup, colors, Divider, InputBase, Paper, TableContainer, TableRow, TextField } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Settings from '../views/Settings';
import { stringToColor } from '../utils/utils';

export const drawerWidth = 240;

const isSetting = window.location.pathname.includes("settings")

export const ContainedButton = styled(Button)(
    ({ theme}: { theme: any }) => ({
      borderRadius: 4,
      border: `0.5px solid ${theme.palette.primary.contrastText}`,
      background:theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      fontWeight: 500,
      // lineHeight: "24px",
      "&:hover ":{
        background:theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: `0.5px solid ${theme.palette.primary.contrastText}`,
      } 
    })
  );

  export const SecondaryButton = styled(Button)(
    ({ theme}: { theme: any }) => ({
      height: 36,
      borderRadius: 4,
      border: `0.5px solid ${theme.palette.primary.contrastText}`, 
      boxShadow:"unset",
      background:theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "24px",
      padding: "4px 16px",
      "&:hover ":{
        background:theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow:"unset",
        border: `0.5px solid ${theme.palette.primary.contrastText}`,
      } 
    })
  );
  export const TextButton = styled(Button)(
    ({ theme}: { theme: any }) => ({
      color: theme.palette.text.primary,
      fontWeight: 800,
      minWidth: "unset",
      // padding:0,
      lineHeight:"8px",
      borderBottom:`1px solid transparent`,
      borderRadius: 0,
      "&:hover":{
        background:"unset",
        borderColor: theme.palette.text.primary,
      }
    })
  );

  export const ButtonWrap = styled(ButtonGroup)(({ theme, active }: { theme?: any, active?: boolean}) => ({
    background: theme.palette.background.paper,
    color:theme.palette.text.primary,
    border: `unset`, 
    boxShadow:"unset",
    borderRadius: 8,
    "&:hover":{
        // border:` 1px solid ${theme.palette.grey[300]}`,
        background: theme.palette.background.paper,
    },
    "& button":{
      border:` 1px solid ${theme.palette.grey[300]}`,
      boxShadow:"unset",
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 500,
      height: 48,
      borderRadius: 8,
      "&:hover":{
        border:` 1px solid ${theme.palette.grey[300]}`,
        background: theme.palette.background.paper ,
        color:theme.palette.primary.contrastText
      },
      
      "&:active":{
        color:theme.palette.primary.main
      },
      "&:last-child":{
        color: theme.palette.text.primary,
        "&:hover":{
        border:` 1px solid ${theme.palette.grey[300]}`,
        background:theme.palette.error.light,
        color:theme.palette.error.main
      },
      "&:first-child":{
        color: theme.palette.primary.main,
        "&:hover":{
          border:` 1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.primary.main,
          color:theme.palette.primary.contrastText
        },
      },
      "&:active":{
        color:theme.palette.error.main
      },
      },
    },
    
  }));

  export const PaperCard = styled(Paper)(({ theme }: { theme: any }) => ({
    background: theme.palette.background.paper,
    boxShadow: `4px 4px 8px -4px #3345401F`,
    borderRadius: 2,
    padding: 16,
    marginBottom: 16,
    border: `1px solid transparent`
  }));

  export const Dividers = styled(Divider)(({ theme }: { theme: any }) => ({
    color: '#D8DEEB',
    borderColor: '#D8DEEB'
  }));

  export const TextInput = styled(TextField)(({ theme }: { theme: any }) => ({
    width: "100% !important",
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Polysans',
    marginTop: 8,
    marginLeft: "0px !important",
    "& input":{
      border: "unset",
      padding:8,
      width: "100%",
      background: theme.palette.background.neutral,
    },
    "& fieldset":{
      // border: "unset",
      // border: `1px solid   rgba(255, 255, 255, 0.23)`, //${theme.palette.grey[300]}
      borderRadius: 3,
      
    }
  }));

  export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop:16,
    height: "calc( 100vh - 295px)",
    background: theme.palette.background.paper, 
    overflow: "auto", 
    borderRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    paddingBottom: 1,
    position:"relative", 
    "& thead":{
      "& th":{
        textTransform: "uppercase",
        letterSpacing: 1.5,
        fontWeight: 900,
        // textAlign: "center",
        "&:last-child":{
        textAlign: "center",
      }
      },
      
    }
  }));

  export const StyledTableRow = styled(TableRow)(({theme})=>({
    '&:last-child td': { 
      border: 0 
    },
    '& td': {
      borderColor:theme.palette.text.black,
      padding: "8px 16px", 
      fontSize:14,  
    } 
  }))

  export const StyledAvatar = styled(Avatar)(({theme, name}:{theme?:any,name:string})=>({
    fontSize:14,
    fontWeight:900,
    color: theme.palette.text.white,
    background: stringToColor(name)

  }))

  export const StyledTooltip =styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.background.default,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary
    },
  }));


  