import { ActionType, AlertType } from "./types";
import * as actionTypes from "../actions";
import { initialAlert } from "../utils/constants";

type UserTypes = {
  userInfo: any,
  isLoading: boolean,
  userRoles: any,
  newUserInfo:any,
  usersList:any,
  alert: AlertType;
  viewmode:string;
  userLoading: boolean
}

const initialState: UserTypes = {
  userInfo: {},
  isLoading: true,
  userRoles: [],
  newUserInfo: {},
  usersList:[],
  alert: initialAlert,
  viewmode:"",
  userLoading:false
  
};

const userInfo = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_USERS_LOADING_START:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.SET_VIEW_MODE:{
      return{
        ...state,
        viewmode: action.payload
      }
    }
    case actionTypes.ON_USERS_LOADING_FINISHED:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.USER_LOADING_START:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.GET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case actionTypes.USER_LOADING_FINISHED:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.GET_USER_ROLES: {
      return {
        ...state,
        userRoles: action.payload,
      };
    }
   
    case actionTypes.SET_NEW_USER_INFO: {
      return {
        ...state,
        newUserInfo: action.payload?.response,
      };
    }
    case actionTypes.GET_USERS_LIST: {
        return {
            ...state,
            usersList: action.payload?.response,
        };
    }
    case actionTypes.UPDATE_USER: {
        return {
            ...state,
            usersList: action.payload,
        };
    }
    case actionTypes.REMOVE_USER: {
        return {
            ...state,
            usersList: action.payload,
        };
    }
    case actionTypes.USER_ALERT: {
        return {
            ...state,
            alert: action.payload,
        };
    }
    case actionTypes.SHOW_ALERT_STATUS: {
        return {
          ...state,
          showAlert: action.payload,
        };
      }
    default: {
      return state;
    }
  }
};

export default userInfo;
