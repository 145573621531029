import React from "react";

const LogsIcon = (props:any) => {
  const {fill, style} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      style={style}
    >
      <path
        d="M19 0.5H1C0.801088 0.5 0.610322 0.579018 0.46967 0.71967C0.329018 0.860322 0.25 1.05109 0.25 1.25V14C0.25 14.3978 0.408035 14.7794 0.68934 15.0607C0.970644 15.342 1.35218 15.5 1.75 15.5H18.25C18.6478 15.5 19.0294 15.342 19.3107 15.0607C19.592 14.7794 19.75 14.3978 19.75 14V1.25C19.75 1.05109 19.671 0.860322 19.5303 0.71967C19.3897 0.579018 19.1989 0.5 19 0.5ZM1.75 6.5H5.5V9.5H1.75V6.5ZM7 6.5H18.25V9.5H7V6.5ZM18.25 2V5H1.75V2H18.25ZM1.75 11H5.5V14H1.75V11ZM18.25 14H7V11H18.25V14Z"
        fill={fill || "#1B2121"}
      />
    </svg>
  );
};

export default LogsIcon;
