import { alpha } from '@mui/material/styles';
import { light } from '@mui/material/styles/createPalette';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#EDF8F5',
  200: '#F6FAF9',
  300: '#D8DEEB',
  400: '#A4AAB7',
  500: '#6C7A8E',
  600: '#1B2121',
  500_0: alpha('#0066FF', 1),
  500_15: alpha('#0066FF', 0.15),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const DARK_GREY = {
  0: '#FFFFFF',
  100:'#000E24',
  200: '#000814',
  300: '#23395C',
  400: '#162640',
  500: '#49576E',
  600: '#0D1C34',
  700: '#1E2B40',
  800: '#8596B1',
  900: '#D1DDEF',
  500_12: alpha('#919EAB', 0.12),
  500_15: alpha('#0066FF', 0.15),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const LIGHT_GREY = {
  0: '#FFFFFF',
  100:'#FAFAFA',
  200: '#E2E5E5',
  300: '#EBEBEB',
  400: '#162640',
  500: '#49576E',
  600: '#0D1C34',
  700: '#1E2B40',
  800: '#031217',
  900: '#D1DDEF',
  500_12: alpha('#919EAB', 0.12),
  500_15: alpha('#0066FF', 0.15),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const BLACK_GREY = {
  0: '#FFFFFF',
  100:'#141C1F',
  200: '#252B2C',
  300: '#1A2225',
  400: '#162640',
  500: '#49576E',
  600: '#0D1C34',
  700: '#1E2B40',
  800: '#031217',
  900: '#D1DDEF',
  500_12: alpha('#919EAB', 0.12),
  500_15: alpha('#0066FF', 0.15),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  main: '#0066FF',
  contrastText: '#fff',
  lighter:'#0066FF26',
  // dark: '#003661',
  light: '#0066FF26'
};


const DARK_PRIMARY = {
  main: '#f3ce39',
  contrastText: '#031217',
  lighter:'#f3ce3933',
  // dark: '#003661',
  light: '#f3ce3933'
};

const SECONDARY = {
  main: '#1B2121',
  contrastText: '#1B2121'
};

const DARK_SECONDARY = {
  main: '#031217',
  contrastText: '#D0CEC8'
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: GREY[800]
};

const SUCCESS = {
  lighter: '#E6FFF3',
  light: '#07db3e26',
  main: '#07DB3E',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};

const WARNING = {
  lighter: alpha('#F8A647', 0.15),
  light: alpha('#F8A647', 0.15),
  main: '#F8A647',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};

const ERROR = {
  lighter: '',
  light: alpha('#F71631', 0.15),
  main: '#F71631',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: GREY[800]
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#7579E7'],
  blue: ['#184DB2', '#3B64B2'],
  green: ['#10B9B9', '#13DA98', '#10B99D', '#36B9B9'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#D94759', '#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
  grey: ['#CBD5E1'],
  orange: ['#F88962', '#FDC57F', '#E2826A']
};

const getPalette = (mode) => {
  return {
    mode,
    ...(mode === 'light'
      ? {
        common: { black: '#fff', white: '#000' },
        primary: { ...DARK_PRIMARY },
        secondary: { main: '#1A1D21', contrastText: '#BAC4CC' },
        info: { ...INFO },
        success: { ...SUCCESS },
        warning: { ...WARNING },
        error: { ...ERROR },
        grey: LIGHT_GREY,
        darkgrey: DARK_GREY,
        gradients: GRADIENTS,
        chart: CHART_COLORS,
        divider: LIGHT_GREY[300],
        text: {
          primary: "#031217",
          secondary: "#031217",
          disabled: GREY[500],
          white: GREY[0],
          black: LIGHT_GREY[800],
          main: GREY[0],
          blue: DARK_GREY[900],
          drawer:  "#D0CEC8",
        },
        background: {
          paper: LIGHT_GREY[100],
          default: LIGHT_GREY[200],
          neutral: LIGHT_GREY[300],
          blue: '#0066FF26',
          drawer: "#031217",
        },
        action: {
          active: LIGHT_GREY[300],
          hover: GREY[500_8],
          selected: GREY[500_16],
          disabled: GREY[500_80],
          disabledBackground: GREY[500_24],
          focus: GREY[500_24],
          hoverOpacity: 0.08,
          disabledOpacity: 0.48
        }
      }
      :{
        common: { black: '#fff', white: '#000' },
        primary: { ...DARK_PRIMARY },
        secondary: { main: '#1A1D21', contrastText: '#BAC4CC' },
        info: { ...INFO },
        success: { ...SUCCESS },
        warning: { ...WARNING },
        error: { ...ERROR },
        grey: BLACK_GREY,
        darkgrey: BLACK_GREY,
        gradients: GRADIENTS,
        chart: CHART_COLORS,
        divider: BLACK_GREY[300],
        text: {
          primary: BLACK_GREY[900],
          secondary: BLACK_GREY[800],
          disabled: GREY[500],
          white: GREY[0],
          black:BLACK_GREY[900],
          main: GREY[0],
          blue: DARK_GREY[900],
          drawer:  "#D0CEC8",
        },
        background: {
          paper: BLACK_GREY[100],
          default: BLACK_GREY[200],
          neutral:BLACK_GREY[300],
          blue: '#0066FF26',
          drawer: "#031217",
        },
        action: {
          active:  BLACK_GREY[300],
          hover: GREY[500_8],
          selected: GREY[500_16],
          disabled: GREY[500_80],
          disabledBackground: GREY[500_24],
          focus: GREY[500_24],
          hoverOpacity: 0.08,
          disabledOpacity: 0.48
        }
      }
    )
  };
};

export default getPalette;
