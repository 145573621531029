import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import { MyThunkAction } from "../types";
import { get, getWithHeader, patchWithHeader, postWithHeader, remove } from "./common";
import { sendAlert } from "./UserAction";
import { History } from "history";
import { APP_ROUTES } from "../utils/constants";
export const GET_STORE_LOADING = "GET_STORE_LOADING";
export const GET_STORE_SUCESS = "GET_STORE_SUCESS";
export const STORE_ALERT = "STORE_ALERT";
export const GET_STORE_METADATA_LOADING = "GET_STORE_METADATA_LOADING";
export const GET_STORE_METADATA_SUCESS = "GET_STORE_METADATA_SUCESS";
export const CLEAN_STORE_METADATA = "CLEAN_STORE_METADATA"

export const GET_STOREINTIGRATION_CONFIG_LOADING = "GET_STOREINTIGRATION_CONFIG_LOADING";
export const GET_STOREINTIGRATION_CONFIG_SUCESS = "GET_STOREINTIGRATION_CONFIG_SUCESS";
export const GET_STOREINTIGRATION_CONFIG_ERROR = "GET_STOREINTIGRATION_CONFIG_ERROR";



export const getStoreIntegrationConfig =
  (marketIntegrationId: string,storeIntegrationId:string,headers:any): MyThunkAction =>
  async (dispatch: any) => {

    dispatch({ type: GET_STOREINTIGRATION_CONFIG_LOADING });
    try {
      const response = await getWithHeader(
        `/integrations/market/${marketIntegrationId}/store/config/${storeIntegrationId}
`,headers);
        if(response?.error){
          dispatch(
            sendAlert({
              text: "Network error, please try again",
              snack: true,
              snackTimeout: 4000,
              snackType: "error",
            }),
          );
          dispatch({ type: GET_STOREINTIGRATION_CONFIG_ERROR, payload: response.error});
        }
        else{
          dispatch({ type: GET_STOREINTIGRATION_CONFIG_SUCESS, payload: response });
        }
      
    } catch (error: any) {
      // dispatch({
      //   type: STORE_ALERT,
      //   payload: error.message || "Failed to fetch partner",
      // });
    }
  };

  export const getStoreIntegrationList =
  (marketIntegrationId: string): MyThunkAction =>
  async (dispatch: any) => {

    dispatch({ type: GET_STORE_LOADING });
    try {
      const response = await get(
        `/integrations/market/${marketIntegrationId}/store/config`
      );
      dispatch({ type: GET_STORE_SUCESS, payload: response?.storeIntegrationList });
    } catch (error: any) {
      dispatch({
        type: STORE_ALERT,
        payload: error.message || "Failed to fetch partner",
      });
    }
  };

export const getStoreMetadata =
  (providerName: string, partnerName: string): MyThunkAction =>
  async (dispatch: any) => {
    dispatch({ type: GET_STORE_METADATA_LOADING });
    try {
      const response = await get(
        `/integrations/config/metadata/${providerName}/${partnerName}/store`
      );
      dispatch({ type: GET_STORE_METADATA_SUCESS, payload: response });
    } catch (error: any) {
      dispatch({
        type: STORE_ALERT,
        payload: error.message || "Failed to fetch partner",
      });
    }
  };

export const createNewStore =
  (
    marketIntegrationId: string,
    data: any,
    headers: any,
    history:History,
    params:any
  ): MyThunkAction =>
  async (dispatch: any) => {
    try {
      const response = await postWithHeader(
        `/integrations/market/${marketIntegrationId}/store/config`,
        data,
        { headers }
      );      

      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Duplicate Provider Store Id",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          })
        );
        return { status: 400 };
      } 
      else{
        dispatch(
          sendAlert({
            text: "Store Created successfully.",
            snack: true,
            snackTimeout: 4000,
            snackType: "success",
          })
        );
        history.push(APP_ROUTES.manageStore,params);
        return { status: 200 };
      }
   
    } catch (error) {
      dispatch(
        sendAlert({
          text: "An unexpected error occurred.",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        })
      );
      return { status: 500, error };
    }
  };

  export const updateStore =
  (
    marketIntegrationId: string,
    storeIntegrationId:string,
    data: any,
    headers: any,
    history:History,
    params:any
  ): MyThunkAction =>
  async (dispatch: any) => {
    try {
      const response = await patchWithHeader(
        `/integrations/market/${marketIntegrationId}/store/config/${storeIntegrationId}`,
        data,
        { headers }
      );

      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Facing Issue",
            snack: true,
            snackTimeout: 4000,
            snackType: "error",
          })
        );
        return { status: 400 };
      }
      else{
        dispatch(
          sendAlert({
            text: "Store Update successfully.",
            snack: true,
            snackTimeout: 4000,
            snackType: "success",
          })
        );
        dispatch(getStoreIntegrationList(marketIntegrationId))
        history.push(APP_ROUTES.manageStore,params);
        
        return { status: 200 };
      }
     
     
      
    } catch (error) {
      dispatch(
        sendAlert({
          text: "An unexpected error occurred.",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        })
      );
      return { status: 500, error };
    }
  };

  export const deleteStore =
  (
    marketIntegrationId: string,
    storeIntegrationId:string,
  ): MyThunkAction =>
  async (dispatch: any) => {
    try {
      const response = await remove(
        `integrations/market/${marketIntegrationId}/store/config/${storeIntegrationId}`,
      );      

      if (response?.error) {
        dispatch(
          sendAlert({
            text: "Facing Issue",
            snack: true,
            snackTimeout: 6000,
            snackType: "error",
          })
        );
        return { status: 400 };
      }
   
      dispatch(
        sendAlert({
          text: "Store Deleted successfully.",
          snack: true,
          snackTimeout: 4000,
          snackType: "success",
        })
      );
      dispatch(getStoreIntegrationList(marketIntegrationId))
      return { status: 200 };
    } catch (error) {
      dispatch(
        sendAlert({
          text: "An unexpected error occurred.",
          snack: true,
          snackTimeout: 4000,
          snackType: "error",
        })
      );
      return { status: 500, error };
    }
  };
