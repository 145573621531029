import { ActionType } from "./types";
import * as actionTypes from "../actions";
import moment from "moment";
import { TIMEZONE } from "../utils/constants";

type AnalyticsTypes = {
  analytics: any,
  analyticsData:any,
  AnalyticsQueryData:{};

}

const initialState: AnalyticsTypes = {
  analytics:{},
  analyticsData: [],
  AnalyticsQueryData:{
    startTime:moment().clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    endTime:moment().format('YYYY-MM-DDTHH:mm:ss'),
   	searchText: "",    // optional parameter, we can pass this to filter more specific data
   	providerName:null, // optional parameter, we can pass this to filter more specific data
   	partnerName:null, // optional parameter, we can pass this to filter more specific data
   	type:null,  // optional parameter, we can pass this to filter more specific data
   	subType:null , // optional parameter, we can pass this to filter more specific data
    status:null,
    timeZone:TIMEZONE,
    marketName: null
  }
  
};

const analytics = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_ANALYTICS_DATA: {    
      const {queryString, forceReset} = action.payload  
      return {
        ...state,
        analytics: action.payload?.response,
        analyticsData: action.payload?.response?.analyticsResponse[0]?.analyticsData,
        AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.SET_ANALYTICS_QUERY_DATA:{      
      const queryString = action.payload
      return {
        ...state,
        AnalyticsQueryData:queryString
      };
    }
    default: {
      return state;
    }
  }
};

export default analytics;
