import { ActionType, AlertType } from "./types";
import * as actionTypes from "../actions";
import moment from "moment";
import { initialAlert, TIMEZONE } from "../utils/constants";

type IntegrationsTypes = {
  integrationStatusSectionList:any,
  isIntegrationStatusLoading:boolean,
  integrationData:any,
  // AnalyticsQueryData:any;
  integrationMetaData:any;
  integrationConfigData:any;
  integrationPartnerConfig:any;
  currentStep:string;
  isLoading:boolean;
 alert: AlertType,
}

const initialState: IntegrationsTypes = {
  integrationStatusSectionList:[],
  integrationData: [],
  integrationMetaData:{},
  integrationConfigData:[],
  integrationPartnerConfig:[],
  currentStep:"SELECT_PROVIDER",
  isLoading:false,
  isIntegrationStatusLoading:false,
   alert: initialAlert,
  // AnalyticsQueryData:{
  //   startTime:moment().clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  //   endTime:moment().format('YYYY-MM-DDTHH:mm:ss'),
  //  	searchText: "",    // optional parameter, we can pass this to filter more specific data
  //  	providerName:null, // optional parameter, we can pass this to filter more specific data
  //  	partnerName:null, // optional parameter, we can pass this to filter more specific data
  //  	type:null,  // optional parameter, we can pass this to filter more specific data
  //  	subType:null , // optional parameter, we can pass this to filter more specific data
  //   status:null,
  //   timeZone:TIMEZONE
  // }
  
};

const integrations = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.GET_INTEGRATION_STATUS_LOADING: {    
      // const {queryString, forceReset} = action.payload  
      return {
        ...state,
        isIntegrationStatusLoading:true
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.GET_INTIGRATION_STATUS_SUCESS: {    
      // const {queryString, forceReset} = action.payload  
      return {
        ...state,
        isIntegrationStatusLoading:false,
        integrationStatusSectionList:action?.payload?.integrationStatusSectionList
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.GET_INTEGRATION_DATA: {    
      // const {queryString, forceReset} = action.payload  
      return {
        ...state,
        integrationData: action.payload?.response,
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.GET_INTEGRATION_METADATA: {    
      return {
        ...state,
        integrationMetaData: action.payload,
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.GET_CONFIG_METADATA: {    
      // const {queryString, forceReset} = action.payload  
      return {
        ...state,
        integrationConfigData: action.payload?.response,
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.GET_PARTNER_METADATA: {    
      const {response, forceReset} = action.payload  
      return {
        ...state,
        integrationPartnerConfig: response,
        isIntegrationStatusLoading:false,
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.SET_CURRENT_STEP: {    
      return {
        ...state,
        currentStep: action.payload,
        // analyticsData: action.payload?.response?.analyticsResponse[0].analyticsData,
        // AnalyticsQueryData: {...(forceReset?{}: state.AnalyticsQueryData ), ...queryString}
      };
    }
    case actionTypes.ON_DATA_LOADING_START:{
      return{
        ...state,
        isLoading: action.payload
      }
    }
    case actionTypes.ON_DATA_LOADING_FINISHED:{
      return{
        ...state,
        isLoading: action.payload
      }
    }

    
          case actionTypes.INTEGRATION_DATA_ALERT:
            return {
              ...state,
              isLoading: false,
              alert: action.payload,
            };
    
    // case actionTypes.SET_ANALYTICS_QUERY_DATA:{      
    //   const queryString = action.payload
    //   return {
    //     ...state,
    //     AnalyticsQueryData:queryString
    //   };
    // }
    default: {
      return state;
    }
  }
};

export default integrations;
